(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("dyna-debounce"), require("react"));
	else if(typeof define === 'function' && define.amd)
		define("react-dynadux", ["dyna-debounce", "react"], factory);
	else if(typeof exports === 'object')
		exports["react-dynadux"] = factory(require("dyna-debounce"), require("react"));
	else
		root["react-dynadux"] = factory(root["dyna-debounce"], root["react"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE_dyna_debounce__, __WEBPACK_EXTERNAL_MODULE_react__) {
return 